<template>
  <div class="card card-questions mb-4">
    <div class="card-header">
      <p>
        Criada por <span class="font-bold">{{ criadaPor }}</span>
      </p>
      <div
        class="card-header__actions d-flex justify-content-md-between align-items-md-end"
      >
        <ul class="card-list list-inline mb-0" id="badgeList">
          <li class="list-inline-item">
            <span class="badge">
              <strong>{{ disciplinaText }}</strong>
            </span>
          </li>
          <li
            v-for="assunto in assuntos"
            :key="assunto.id"
            class="list-inline-item"
          >
            <span class="badge">{{ assunto.text }}</span>
          </li>
          <li class="list-inline-item">
            <span class="badge">{{ questaoTipo }}</span>
          </li>
          <li class="list-inline-item">
            <span class="badge">{{ questaoNivel }}</span>
          </li>
        </ul>

        <ul class="card-list list-inline mb-0" id="actionsList">
          <li
            v-if="permiteEditar"
            class="list-inline-item"
          >
            <router-link
              :to="{ name: 'questao_edit', params: { id: questaoId } }"
              class="link font-bold"
            >
              <img
                :src="require('../../assets/images/edit.svg')"
                class="mr-1"
              />Editar
            </router-link>
          </li>

<!-- Este excluir apenas tira a questão da lista de questões da prova -->
          <li v-if="isListInseridaProva" class="list-inline-item">
            <a href="#" @click.prevent="deleteQuestaoDaProva"
              ><img
                :src="require('../../assets/images/delete.svg')"
                class="mr-1"
              />Excluir</a
            >
          </li>

          <li
            v-if="isListProva && !isListInseridaProva"
            class="list-inline-item"
          >
            <button class="btn btn-classic" @click="insereNaProva">
              Inserir nesta prova
            </button>
          </li>
          <!-- Este excluir joga a questão pra lixeira de questões -->
          <li
            v-if="permiteExcluir"
            class="list-inline-item"
          >
            <a href="#" @click.prevent="deleteQuestao"
              ><img
                :src="require('../../assets/images/delete.svg')"
                class="mr-1"
              />Excluir</a
            >
          </li>
          <li
            v-if="!isListProva && !isListInseridaProva"
            class="list-inline-item"
          >
            <a href="#" @click="favoritarQuestao"
              ><img
                :src="require('../../assets/images/favorite.svg')"
                class="mr-1"
              />Favorito</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="card-body mb-3">
      <p class="card-title">Enunciado</p>

      <div class="content mb-2" v-html="editorData" />
      <div class="form-group form-group-repeater d-flex flex-column">
        <label for="">
          <button
            class="btn btn-outline btn-outline__sec"
            @click="toggleRespostas"
          >
            <i
              class="fas fa-eye"
              :class="[showAlternativas ? 'fa-eye-slash' : 'fa-eye']"
            />
            Respostas
          </button>
        </label>

        <div v-if="showAlternativas">
          <div
            v-for="(alternativa, index) in alternativas"
            :key="alternativa.id"
            class="input-group mb-2"
          >
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  v-model="alternativas[index].isCorreta"
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  disabled
                />
              </div>
              <span class="input-group-text">a)</span>
            </div>

            <div class="" />

            <input
              v-model="alternativas[index].texto"
              type="text"
              class="form-control"
              aria-label="Dollar amount (with dot and two decimal places)"
              readonly
            />
          </div>
        </div>
      </div>
      <div
        class="card-header__actions d-flex justify-content-md-between align-items-md-end"
      >
        <ul class="card-list list-inline mb-0"></ul>
        <ul class="card-list list-inline mb-0">
          <li
            v-if="isListInseridaProva && ordemProva == 'Sequencial'"
            class="list-inline-item"
          >
            <div class="input-group" v-if="isListInseridaProva">
              <div class="input-group-prepend">
                <div id="btnGroupAddon" class="input-group-text">
                  Valor da questão
                </div>
              </div>
              <input
                type="number"
                step="0.1"
                min="1"
                class="form-control"
                style="width: 100px"
                aria-describedby="btnGroupAddon"
                v-model="peso"
                @change="soma(peso)"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { checkUserHaveRole } from "@/tools";
import { constantes } from "@/constants";
export default {
  components: {},
  props: {
    questaoProp: {
      type: Object,
    },
    ordemProva: {
      type: String,
    },
    isListProva: {
      type: Boolean,
      required: true,
      default: false,
    },
    isListInseridaProva: {
      type: Boolean,
      required: true,
      default: false,
    },
    previewProva: {
      type: Boolean,
      required: false,
      default: false,
    },
    preview: {
      type: Boolean,
      required: false,
      default: true,
    },
    emailCriador: {
      type: String,
    },
  },
  data() {
    console.log(this.questaoProp)
    return {
      showAlternativas: false,
      alternativas: this.questaoProp.questao ? this.questaoProp.questao.alternativas : this.questaoProp.alternativas,
      editorData: this.questaoProp.questao ? this.questaoProp.questao.enunciado : this.questaoProp.enunciado,
      assuntos: this.questaoProp.questao ? this.questaoProp.questao.assuntos : this.questaoProp.assuntos,
      criadaPor: this.questaoProp.questao ? this.questaoProp.questao.criadaPor.email : this.questaoProp.criadaPor.email,
      peso: this.questaoProp.peso,
      minhaQuestao: false,
    };
  },
  computed: {
    disciplinaText() {
      return this.questaoProp.questao ? this.questaoProp.questao.disciplina.text : this.questaoProp.disciplina.text
    },

    questaoTipo() {
      return this.questaoProp.questao ? this.questaoProp.questao.tipo : this.questaoProp.tipo
    },

    questaoNivel() {
      return this.questaoProp.questao ? this.questaoProp.questao.nivel : this.questaoProp.nivel
    },

    questaoId() {
      return this.questaoProp.questao ? this.questaoProp.questao.id : this.questaoProp.id
    },

    permiteEditar() {
      return !this.isListProva && !this.isListInseridaProva && this.minhaQuestao
    },

    permiteExcluir() {
      return !this.isListProva && !this.isListInseridaProva && (this.minhaQuestao || checkUserHaveRole([constantes.ROLE_SUPER_ADMIN]))
    },

  },
  created() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$api
        .get("users/info")
        .then((response) => {
          this.user = response.data;

          this.minhaQuestao = this.user.user.email == this.emailCriador
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    deleteQuestaoDaProva() {
      this.$parent.deletaQuestao(this.questaoProp);
    },
    insereNaProva() {
      this.$parent.insereQuestao(this.questaoProp);
    },
    toggleRespostas() {
      this.showAlternativas = !this.showAlternativas;
    },
    deleteQuestao() {
      this.$parent.deleta(this.questaoProp);
    },
    favoritarQuestao() {
      this.$parent.favoritarQuestao(this.questaoProp);
    },
    soma(data) {
      this.$parent.somaPeso(data, this.questaoProp);
    },
  },
};
</script>

<style lang="scss" scoped>
.badge_assunto {
  background-color: #26a54e !important;
  color: #fff !important;
}
.badge {
  width: 100%;
  height: auto;
  white-space: normal;
  text-align: start;
}

.card-questions .card-body .content img{
    width: 100% !important
}

.card-header__actions{
  flex-direction: column-reverse;
}

#badgeList{
  align-self: start;
}

#actionsList {
  align-self: end;
}
</style>
